export const webSiteModule = {
  // namespaced: true,
  state() {
    return {
      basics: {
        activeTourID: '',
        currentDate: null,
        currentYear: null,
        // eloLogoSVG: "https://www.elo.com/typo3conf/ext/nelo_elocom_templates/Resources/Public/Images/logo_elo.svg",
        eloLogoSVG: "logo_elo.svg",
        appTourViewMode: false,
        appNewTourCreatingMode: false,
        appTourEditingMode: true,
        appValidationMode: false,
        toastMessages: [],
        ajaxCountryNames: null,
        tourCountryNameActiveIndex: null,
        tourTitleAjaxActiveIndex: null,
        tourTitlesFormAjaxActiveIndex: null,
        ruleOf3Hours: false,                            // Daily allowence is due only if ONSHORE time duration is >= 3 hours
        isPortrait: false,
        isTouchDevice: false
      },
      validation: {
        tourTitles: false,
        stationsTitles: [],
        stationsCountryNames: [],
        stationsDates: [],
        stationsTimes: [],
        pkw: {},
      },
      localization: {
        userCSSsettings: "light",
        UIActiveLanguage: "Deutsch",
        UIActiveLanguageCode: "de",
        UILanguageOptions: [
          {
            code: "en",
            text: "English",
            flag: "&#x1F1EC;&#x1F1E7;"
          },
          {
            code: "de",
            text: "Deutsch",
            flag: "&#x1F1E9;&#x1F1EA;"
          }
        ]
      },
      pdfSettings: {
        logo: '',
        primaryColor: '',
        secondaryColor: '',
        signatureAuthorityName: '',
        approvalDate: '',
        location: '',
        includeCateringDatesTable: '',
        logoPosition: '',
        // invoiceNumber: "5979 / 002",
        // approvalDateisToday: false,
        // signatureDate: '',
      },
      en: {
        footer: {
          copyright_text: "Copyright ©",
          poweredByPrefixText: "Powered by",
          poweredByText: "ELO AT",
          poweredByLink: "#"
        },
        general: {
          subtotalText: "Subtotal",
          currencySign: "€",
          metricSystem: "km",
          employee: "per passenger",
          delimiter: "/",
          hoursText: "hour(s)",
          fullDayText: "full day",
          brutto: "Gross",
          vat: "VAT",
          language: "language"
        },
        buttons: {
          next: "Next",
          back: "Back",
          backHome: "To Home Page",
          save: "Save",
          saveAndNext: "Save",
          addTour: "Add tour",
          edit: "Edit",
          tourOverview: "Tour Overview",
          delete: "Delete",
          sentforapproval: "Send for approval",
          pdf_preview: "PDF preview",
          pdf_download: "PDF download",
          pdf_settings: "PDF setup",
          yes: "Yes",
          no: "No",
          abort: "Cancel",
          toApproval: "Send for approval",
          cancel: "Cancel",
        },
        page404: {
          title: "This is the 404 Error Page",
          subTitle: "Page not found. Please, check the URL or go to one of the links:",
        },
        pokaYoke: {
          deleteTourText: 'Are you sure you want to delete the tour?',
          warningTexts: {
            warningText: "The confirmation enables completion by submitting: Claims under § 8 Travel Expenses and Travel Cost Reimbursements must be made no later than - 4 months - after the end of the business trip or the agreed or required submission of the logbook, otherwise they expire with the employer by issuing an invoice or submitting the logbook. The recording and delivery is carried out personally by the employee.",
            min25kmText: "Confirm that the travel destination is at least 25 km away from the center of your activity (workplace)?",
            min3hoursText: "Confirm that the travel duration is more than 3 hours?"
          },
          approvalTitle: "Confirm the following conditions",
          signatureTitle: "Sign the PDF Document here:",
          signatueOpenBtnText: "Sign"
        },
        toastMessageTexts: {
          tourTitleisEmptyTitle: "Required input field",
          tourTitleisEmptyDescription: "Tour title can not be empty! Please enter a title",
          stationTitle: "Station Title",
          stationTitleDesctiption: "Station Title can NOT be empty",
          countyIsEmptyTitle: "Country name",
          countyIsEmptyDescription: "Destination's country name can NOT be empty. In case of an onshore business trip, please, make sure that all stations has the same country name.",
          datesAreNotConsecutiveTitle: "Check date&time",
          datesAreNotConsecutiveDescription: "Date&time must be NOT empty and be in the future relative to the date of previous station",
          pkwDataErrorTitle: "Private vehicle fields",
          pkwDataErrorDesctiption: "If you have checked the 'Private vehicle' checkbox - please, make sure you have filled the required fields",
          savedAsPDF: "Tour was successfully saved in PDF format.",
          sentforapproval: "Your request was successfully sent for approval.",
          tourDeleted: "Tour was successfully deleted",
          messageType1: "error",
          messageType2: "success",
        },
        tourStationTitles: {
          title: "Business trip stations",
          subtitle: "Enter the local time that was valid in the visited country and not that of your home country.",
          offshoreTrip: "Travelling abroad?"
        },
        catering: {
          title: "Daily diets",
          tableHeader: ["Date", "Country", "Duration", "Flat rate", "Lunch", "Dinner", "Sum"],
          errorMessage: "You have not entered a route yet. Please enter a travel PERIOD (date & time) and make sure you have filled the COUNTRY name of each destination.",
          inputs: [
            {
              title: "Daily allowances at home country/abroad (diets)",
              subtitle: null,
              // fields: [
              //   {
              //     placeholder: "Mittagessen",
              //     value: null,
              //     symbol: [],
              //     attributes: {
              //       type: "checkbox",
              //       checked: false
              //     }
              //   },
              //   {
              //     placeholder: "Abendessen",
              //     value: null,
              //     symbol: [],
              //     attributes: {
              //       type: "checkbox",
              //       checked: false
              //     }
              //   }
              // ]
            }
          ]
        },
        transportation: {
          title: "Transport Expenses",
          car: {
            title: "Private vehicle",
            subtitle: "*Distance to the destination at least 25 km; *(0,05€/km a passenger)",
            fields: {
              licensePlate: {
                placeholder: "License Plate",
                attributes: {
                  type: "text",
                  name: "licence_plate"
                }
              },
              passengersCount: {
                placeholder: "Number of passengers (max 6)",
                symbol: "", //"Passagiere",
                attributes: {
                  // "data-attribute": "exception",
                  min: 0,
                  max: 6,
                  type: "number",
                  "data-attribute": "passengers",
                }
              },
              kmFlatRate: {
                placeholder: "Mileage allowance",
                symbol: ["currencySign", "metricSystem"],
                attributes: {
                  disabled: "disabled",
                  type: "text"
                }
              },
              kmBefore: {
                placeholder: "Odometer start",
                symbol: ["metricSystem"],
                attributes: {
                  type: "number",
                }
              },
              kmAfter: {
                placeholder: "Odometer finish",
                symbol: ["metricSystem"],
                attributes: {
                  type: "number",
                }
              },
              kmCounter: {
                placeholder: "Driven Km",
                symbol: ["metricSystem"],
                attributes: {
                  type: "number",
                  disabled: "disabled",
                }
              }
            }
          },
          public_transport: {
            title: "Public transport",
            subtitle: "(Bus, taxi etc. according to attached receipts)",
            fields: [
              {
                placeholder: "Public transport",
                value: null,
                constant: null,
                symbol: ["currencySign"],
                attributes: {
                  type: "number"
                }
              }
            ]
          }
        },
        accommodation: {
          title: "Accommodation costs",
          inputs: [
            {
              title: "Actual costs",
              subtitle: "(according to the attached documents and without food!)",
              fields: [
                {
                  placeholder: "Accommodation costs",
                  // value: null,
                  symbol: ["currencySign"]
                }
              ]
            }
          ]
        },
        travelExpenses: {
          title: "Travel Expenses",
          inputs: [
            {
              title: "Actual costs",
              subtitle: "(according to the attached documents, e.g. for parking, telephone, postage, etc.)",
              fields: [
                {
                  placeholder: "Travel Expenses",
                  // value: null,
                  symbol: ["currencySign"]
                }
              ]
            }
          ]
        },
        tourCosts: {  
          transportation: "Transportation",
          travelExpenses: "Travel Expenses",
          catering: "Daily diets",
          accommodation: "Accommodation",
          // grandTotal: "Tour Grandtotal"
        },
        grandTotals: {
          title: "Tour Expenses Overview",
          text: "Tour Grandtotal"
        },
        myToursOverview: {
          title: "My tours overview",
          noData: "No data found",
          tableHeaders: {
            number: "Date",
            title: "Purpose",
            // employee: "Employee",
            companyName: "Company",
            tourStatus: "Status",
            comment: "Comment",
            total: "Sum",
            action: "Actions"
          }
        },
        placeholders: {
          destination: {
            startTourFrom: "Start from",
            goTo: "Got to",
            endTourAt: "End of Trip",
            country: "Country"
          },
          timestamp: {
            date: "Date",
            time: "Time",
            start: "Start",
            end: "End",
            goTo: "Arrival",
            goOffshore: "Departure",
          },
          date: {
            startTourFrom: "Start date",
            goTo: "Arrival date",
            endTourAt: "End date"
          },
          time: {
            startTourFrom: "Start time",
            goTo: "Arrival time",
            endTourAt: "End time"
          }
        },
        pdf: {
          title: "Travel expense report",
          invoiceNumberTitle: "Invoice №",
          costsTableHeader: ["Cost Title", "Subtotal"],
          logoBtnText: "upload logo",
          referenceDate: "(Date, Location)",
          referenceSignature: "(Signature)",
          logoPositionTitle: "Logo Position (L/R)",
          includeDatesTableTitle: "Include Dated Table?",
          fromText: "from",
          toText: "to",
          dateOn: "on",
          timeAt: "at",
          hoursText: "hour(s)",
        },
        month: ["January","February","March","April","May","June","July","August","September","October","November","December"],
        tourStatus: {
          0: "Draft",
          1: "In progress",
          2: "Approved",
          3: "Declined",
          4: "Paid"
        },
        calculationRules: {
          text: "* Daily allowances calculation method",
          text_12: "12-hours Rule",
          text_24: "24-hours Rule",
        },
        tourTitles: {
          tourOverview: "Tour Overview",
          tourIsEmptyMessage: "You have not entered the tour dates and times yet!",
          title: "Information",
          nameFields: {
            title: {
              // title: "KickOFF",
              placeholder: "Purpose of the tour",
              attributes: {
                required: true,
                id: "designation",
                name: "designation"
              }
            },
            employee: {
              // title: "Dima Shreyder",
              placeholder: "Name",
              attributes: {
                required: false,
                id: "name",
                name: "name"
              }
            },
            company: {
              // title: "ELO AT",
              placeholder: "Company",
              attributes: {
                required: false,
                id: "company",
                name: "company"
              }
            }
          }
        }
      },
      de: {
        footer: {
          copyright_text:  "Copyright ©",
          poweredByPrefixText: "Powered by",
          poweredByText: "ELO AT",
          poweredByLink: "#"
        },
        general: {
          subtotalText: "Zwischensumme",
          currencySign: "€",
          metricSystem: "km",
          employee: "pro Beifahrer",
          delimiter: "/",
          hoursText: "Stunde(n)",
          fullDayText: "ganztätig",
          brutto: "Brutto",
          vat: "davon Vorsteuer",
          language: "Sprache"
        },
        buttons: {
          next: "Weiter",
          back: "Zurück",
          backHome: "Zur Startseite",
          save: "Speichern",
          saveAndNext: "Speichern",
          addTour: "Reise hinzufügen",
          edit: "Ändern",
          tourOverview: "Reise Übersicht",
          delete: "Löschen",
          sentforapproval: "Genehmigung", // einreichen",
          pdf_preview: "PDF Vorschau",
          pdf_download: "PDF Laden",
          pdf_settings: "PDF Setup",
          yes: "Ja",
          no: "Nein",
          abort: "Abbrechen",
          toApproval: "Zur Genehmigung",
          cancel: "Abbrechen",
        },
        page404: {
          title: "Dies ist die 404-Fehlerseite",
          subTitle: "Seite nicht gefunden. Bitte überprüfen Sie die URL oder gehen Sie zu einem der Links:",
        },
        pokaYoke: {
          deleteTourText: 'Sind Sie sicher, dass Sie die Tour löschen möchten?',
          warningTexts: {
            warningText: "Die Bestätigung ermöglicht den Abschluss durch Einreichen von: Ansprüche im Sinne des § 8 Reisekosten und Reisekosten- vergütungen müssen spätestens innerhalb von - 4 Monaten - nach Beendigung der Dienstreise/Geschäftsreise oder der vereinbarten oder verlangten Vorlage des Fahrtenbuches geltend gemacht werden, andernfalls erlöschen sie mit dem Arbeitgeber durch Ausstellung einer Rechnung oder Vorlage des Fahrtenbuches . Die Erfassung und Zustellung erfolgt persönlich durch den Mitarbeiter.",
            min25kmText: "Bestätigen Sie, dass das Reiseziel mindestens 25 km vom Mittelpunkt Ihrer Tätigkeit (Betriebsstätte) entfernt ist?",
            min3hoursText: "Bestätigen Sie, dass die gesamte Reisedauer von mehr als 3 Stunden vorliegt?"
          },
          approvalTitle: "Bestätigen Sie die folgenden Bedingungen",
          signatureTitle: "Unterschreiben Sie das PDF-Dokument",
          signatueOpenBtnText: "Unterschreiben"
        },
        toastMessageTexts: {
          tourTitleisEmptyTitle: "Erforderliches Eingabefeld",
          tourTitleisEmptyDescription: "Tourtitel darf nicht leer sein! Bitte geben Sie einen Titel ein",
          stationTitle: "Reisestation Titel",
          stationTitleDesctiption: "Der Titel des Reiseziels darf NICHT leer sein",
          countyIsEmptyTitle: "Land Name",
          countyIsEmptyDescription: "Das Land des Reiseziels darf NICHT leer sein. Bei einer Inlandgeschäftsreise stellen Sie bitte sicher, dass alle Stationen den gleichen Ländernamen haben.",
          datesAreNotConsecutiveTitle: "Datum&Uhrzeit",
          datesAreNotConsecutiveDescription: "Das Datum & Uhrzeit darf NICHT leer sein und in Bezug auf das Datum der vorherigen Station in der Zukunft liegen",
          pkwDataErrorTitle: "Privat KFZ Felder",
          pkwDataErrorDesctiption: "Wenn Sie das Kontrollkästchen 'Fahrt mit privatem KFZ' aktiviert haben, stellen Sie bitte sicher, dass Sie die KFZ Datenfelder ausgefüllt haben",
          savedAsPDF: "Die Tour wurde erfolgreich im PDF-Format gespeichert.",
          sentforapproval: "Ihre Anfrage wurde erfolgreich zur Genehmigung gesendet.",
          tourDeleted: "Die Tour wurde erfolgreich gelöscht.",
          messageType1: "Fehler",
          messageType2: "Erfolg",
        },
        tourStationTitles: {
          title: "Reisestationen",
          subtitle: "Geben Sie die ORTSzeit ein, die in dem besuchten Land galt und nicht die Ihres Heimatlandes.",
          offshoreTrip: "Auslandsreise?"
        },
        catering: {
          title: "Tagesdiäten",
          tableHeader: ["Datum", "Land", "Dauer", "Pauschale", "Mittagessen", "Abendessen", "Summe"],
          errorMessage: "Sie haben noch keine Route eingegeben. Bitte geben Sie einen Reisezeitraum (Datum und Uhrzeit) ein und stellen Sie sicher, dass Sie den Ländernamen jedes Reiseziels eingegeben haben.",
          inputs: [
            {
              title: "Tagesgelder im Inland/Ausland (Diäten)",
              subtitle: null,
            }
          ]
        },
        transportation: {
          title: "Fahrtkosten",
          car: {
            title: "Fahrt mit privatem KFZ",
            subtitle: "*Entfernung zum Reiseziel mindestens 25 km; (0,05€/km pro Beifahrer)",
            fields: {
              licensePlate: {
                placeholder: "Auto Kennzeichen",
                attributes: {
                  type: "text",
                  name: "licence_plate"
                }
              },
              passengersCount: {
                placeholder: "Beifahrer Anzahl (max 6)",
                symbol: "", //"Passagiere",
                attributes: {
                  min: 0,
                  max: 6,
                  type: "number",
                  "data-attribute": "passengers",
                }
              },
              kmFlatRate: {
                placeholder: "Kilometergeld",
                symbol: ["currencySign", "metricSystem"],
                attributes: {
                  disabled: "disabled",
                  type: "number"
                }
              },
              kmBefore: {
                placeholder: "Kilometerstand Start",
                symbol: ["metricSystem"],
                attributes: {
                  type: "number",
                }
              },
              kmAfter: {
                placeholder: "Kilometerstand Ende",
                symbol: ["metricSystem"],
                attributes: {
                  type: "number",
                }
              },
              kmCounter: {
                placeholder: "Gefahrene Km",
                symbol: ["metricSystem"],
                attributes: {
                  type: "number",
                  disabled: "disabled",
                }
              },
            }
          },
          public_transport: {
            title: "Öffentliche Verkehrmittel",
            subtitle: "(Bus, Taxi etc. laut beigefügten Belegen)",
            fields: [
              {
                placeholder: "Öffentliche Verkehrmittel",
                value: null,
                constant: null,
                symbol: ["currencySign"],
                attributes: {
                  type: "number"
                }
              }
            ]
          }
        },
        accommodation: {
          title: "Übernachtungskosten",
          inputs: [
            {
              title: "Tatsächliche Kosten",
              subtitle: "(laut beigefügten Belegen und ohne Verpflegung!)",
              fields: [
                {
                  placeholder: 'Übernachtungskosten',
                  symbol: ["currencySign"]
                }
              ]
            }
          ]
        },
        travelExpenses: {
          title: "Reisenebenkosten",
          inputs: [
            {
              title: "Tatsächliche Kosten",
              subtitle: "(laut beigefügten Belegen, z.B. für Parkplatz, Telefon, Porto etc.)",
              fields: [
                {
                  placeholder: "Reisenebenkosten",
                  value: null,
                  symbol: ["currencySign"]
                }
              ]
            }
          ]
        },
        tourCosts: {  
          transportation: "Fahrtkosten",
          travelExpenses: "Reisenebenkosten",
          catering: "Tagesdiäten",
          accommodation: "Übernachtungskosten",
        },
        grandTotals: {
          title: "Reisekosten im Überblick",
          text: "Reisekosten Gesamt"
        },
        myToursOverview: {
          title: "Meine Reisen im Überblick",
          noData: "Keine Reisen gefunden",
          tableHeaders: {
            number: "Datum",
            title: "Zweck",
            // employee: "MA Name",
            companyName: "Firma",
            tourStatus: "Status",
            comment: "Kommentar",
            total: "Betrag",
            actions: "Aktionen"
          }
        },
        placeholders: {
          destination: {
            startTourFrom: "Start von",
            goTo: "Reise nach",
            endTourAt: "Ende der Reise",
            country: "Land"
          },
          timestamp: {
            date: "Datum",
            time: "Zeit",
            start: "Start",
            end: "Ende",
            goTo: "Ankunft",
            goOffshore: "Ausreise",
          },
          date: {
            startTourFrom: "Startdatum",
            goTo: "Ankunftsdatum",
            endTourAt: "Enddatum"
          },
          time: {
            startTourFrom: "Startzeit",
            goTo: "Ankunftszeit",
            endTourAt: "Endzeit"
          }
        },
        pdf: {
          title: "Reisekostenabrechnung",
          invoiceNumberTitle: "Rechnung №",
          costsTableHeader: ["Aufwand Titel", "Zwischensumme"],
          logoBtnText: 'Logo hochladen',
          referenceDate: "(Datum, Ort)",
          referenceSignature: "(Unterschrift)",
          logoPositionTitle: "Logo Position (L/R)",
          includeDatesTableTitle: "Mit Tagesdiäten Tabelle?",
          fromText: "von",
          toText: "bis",
          dateOn: "am",
          timeAt: "um",
          hoursText: "Uhr",
        },
        month: ["Jänner","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
        tourStatus: {
          0: "Entwurf",
          1: "In Bearbeitung",
          2: "Genehmigt",
          3: "Abgelehnt",
          4: "Ausbezahlt"
        },
        calculationRules: {
          text: "* Tagesdiäten Berechnungsmethode",
          text_12: "12-Stunden Regel",
          text_24: "24-Stunden Regel",
        },
        tourTitles: {
          tourOverview: "Routenübersicht",
          tourIsEmptyMessage: "Sie haben noch kein Reiseziel angegeben",
          title: "Information",
          nameFields: {
            title: {
              userDBfieldID: "title",
              placeholder: "Zweck der Reise",
              attributes: {
                required: true,
                id: "designation",
                name: "designation"
              }
            },
            company: {
              placeholder: "Firma",
              attributes: {
                required: false,
                id: "company",
                name: "company"
              }
            },
            employee: {
              placeholder:"Name",
              attributes: {
                required: false,
                id: "name",
                name: "name"
              }
            }
          }
        }
      }
    }
  },
  getters: {
    basics(state) {
      return state.basics;
    },
    language: (state) => state.localization.UIActiveLanguageCode,
    localization: (state) => state.localization,
    validation: (state) => state.validation,
    // footerBasics: (state) => state.en.footer,
    getMonth: (state) => (monthNumber) => state[state.localization.UIActiveLanguageCode].month[monthNumber], //.slice(0, 4)
    vocabulary: (state) => state[state.localization.UIActiveLanguageCode],
    pdfSettings: (state) => state.pdfSettings,
    getFlagCode: (state) => (countryCode) => {
      if(countryCode) {
        const normalizedCode = countryCode.toLowerCase();
        const flagKey = state.localization.UILanguageOptions.find(lang => lang.code.toLowerCase() === normalizedCode);
        return flagKey ? flagKey.flag : ''; //'Flag not available'
      }
      return null;
    },
  },
  mutations: {
    SWITCH_LANGUAGE(state, value) {
      state.localization.UIActiveLanguage = value.text;
      state.localization.UIActiveLanguageCode = value.code;
      this.state.userDB.settings.language = value;
    },
    ACTIVE_TOUR_ID(state, { tourID }) {
      // Check if the tourID exists in the tours object
      if (Object.keys(this.state.userDB.tours).length != 0 && this.state.userDB.tours[tourID]) {
        state.basics.activeTourID = tourID;  // Directly set the active tour ID
      } else {
        console.log('Tour with ID', tourID, 'not found.');
      }
    },

    SET_APP_TOUR_VIEW_MODE(state, appMode ) {
      state.basics.appTourViewMode = appMode;
      // console.log('Setting appTourViewMode: ', state.basics.appTourViewMode);
    },

    SET_APP_NEW_TOUR_CREATING_MODE(state, { modeStatus }) {
      state.basics.appNewTourCreatingMode = modeStatus;
    },
    SET_APP_TOUR_EDITING_MODE(state, { modeStatus }) {
      state.basics.appTourEditingMode = modeStatus;
    },

    SET_APP_FIELDS_VALIDATION_MODE(state, { modeStatus }) {
      state.basics.appValidationMode = modeStatus;
    },

    SET_CURRENT_YEAR(state) {
      let today = new Date();
      state.basics.currentYear = today.getFullYear();
      state.basics.currentDate = today; //`${today.getFullYear()}-${(today.getUTCMonth() + 1) < 10 ? '0' : ''}${today.getUTCMonth() + 1}-${today.getDate()}`;
    },

    DETECT_TOUCH_DEVICE(state) {
      state.basics.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    },

    SET_ORIENTATION(state, isPortrait) {
      state.basics.isPortrait = isPortrait;
    },

    SET_LOGO_URL(state, logo) {
      state.pdfSettings.logo = logo;
      this.dispatch('axiosUpdatePDFSettings');
    },
    SWITCH_PDF_LOGO_POSITION(state, value) {
      state.pdfSettings.logoPosition = value;   
      this.dispatch('axiosUpdatePDFSettings');
    },
    UPDATE_PRIMARY_COLOR(state, value) {
      state.pdfSettings.primaryColor = value;
      this.dispatch('axiosUpdatePDFSettings');
    },
    UPDATE_SECONDARY_COLOR(state, value) {
      state.pdfSettings.secondaryColor = value;
      this.dispatch('axiosUpdatePDFSettings');
    },
    UPDATE_PDF_SIGN_NAME(state, value) {
      state.pdfSettings.signatureAuthorityName = value;
      this.dispatch('axiosUpdatePDFSettings');
    },
    UPDATE_PDF_INVOICE_NUMBER(state, value) {
      state.pdfSettings.invoiceNumber = value;
    },
    INCLUDE_DATES_TABLE_IN_PDF(state, value) {
      state.pdfSettings.includeCateringDatesTable = value;
      this.dispatch('axiosUpdatePDFSettings');
    },
    
    UPDATE_LOCATION_IN_PDF(state, value) {
      state.pdfSettings.location = value;
      this.dispatch('axiosUpdatePDFSettings');
    },
    
    APPROVAL_DATE_IS_TODAY(state, today) {
      state.pdfSettings.approvalDate = state.useFormatDate(today);
      this.dispatch('axiosUpdatePDFSettings');
    },
    // UPDATE_PDF_SIGNING_DATE(state, value) {
    //   state.pdfSettings.signingDate = value;
    // },
    // SWITCH_APPROVAL_DATE_IS_TODAY(state, value) {
    //   state.pdfSettings.approvalDateisToday = value;
    // },
    ADD_TOAST_MESSAGE(state, { messageTitle, messageDescription, messageType }) {
      state.basics.toastMessages.push(
        {
          title: `${messageTitle}`,
          description: messageDescription,
          type: messageType,
          visible: true
        }
      );
    },
    HIDE_TOAST_MESSAGE(state, { index }) {
      // state.basics.toastMessages.splice(index, 1);
      // console.log('visible: ', state.basics.toastMessages[index].visible);
      // console.log('visibles: ', state.basics.toastMessages);
      state.basics.toastMessages[index].visible = false;
    },

    UPDATE_ERRORS_TOUR_TITLES(state, stationTitlesErrors) {
      state.validation.tourTitles = stationTitlesErrors;
    },
    UPDATE_ERRORS_STATION_TITLES(state, stationTitlesErrors) {
      state.validation.stationsTitles = stationTitlesErrors;
    },
    UPDATE_ERRORS_COUNTRIES(state, stationErrors) {
      state.validation.stationsCountryNames = stationErrors;
    },

    UPDATE_ERRORS_DATES(state, stationsDateErrors) {
      state.validation.stationsDates = stationsDateErrors;
    },

    UPDATE_ERRORS_TIMES(state, stationsTimes) {
      state.validation.stationsTimes = stationsTimes;
    },
    UPDATE_ERRORS_PKW(state, pkwFieldsErrors) {
      state.validation.pkw = { ...pkwFieldsErrors } ;
    }
  },
  actions: {
    getGeneralData() {
      this.commit('SET_CURRENT_YEAR');
      this.commit('DETECT_TOUCH_DEVICE');
    },

    updateOrientation({ commit }, isPortrait) {
      commit('SET_ORIENTATION', isPortrait);
    },

    setActiveTourID(state, { tourID }) {
      this.commit('ACTIVE_TOUR_ID', { tourID });
    },

    setAppTourViewMode(state, appMode) {
      this.commit('SET_APP_TOUR_VIEW_MODE', appMode);
    },

    setAppNewTourCreatingMode(state, modeStatus) {
      this.commit('SET_APP_NEW_TOUR_CREATING_MODE', { modeStatus });
    },
    setAppTourEditingMode(state, modeStatus) {
      this.commit('SET_APP_TOUR_EDITING_MODE', { modeStatus });
    },

    addToastMessages(state, { messageTitle, messageDescription, messageType }) {
      this.commit('ADD_TOAST_MESSAGE', { messageTitle, messageDescription, messageType });

      let index = this.state.webSiteModule.basics.toastMessages.length - 1;
      setTimeout(() => {
        this.dispatch('hideToastMessage', { index });
      }, 4000);
    },

    async hideToastMessage(state, { index }) {
      this.commit('HIDE_TOAST_MESSAGE', { index });
    },

    updateErrorsTourTitles(state, tourTitlesError) {
      this.commit('UPDATE_ERRORS_TOUR_TITLES', tourTitlesError);
    },

    updateErrorsStationTitles(state, stationTitlesErrors) {
      this.commit('UPDATE_ERRORS_STATION_TITLES', stationTitlesErrors);
    },
    updateErrorsCountries(state, stationErrors) {
      this.commit('UPDATE_ERRORS_COUNTRIES', stationErrors);
    },
    updateErrorsDates(state, stationsDateErrors) {
      this.commit('UPDATE_ERRORS_DATES', stationsDateErrors);
    },
    updateErrorsTimes(state, stationsTimes) {
      this.commit('UPDATE_ERRORS_TIMES', stationsTimes);
    },
    updateErrorsPKW(state, pkwFieldsErrors) {
      this.commit('UPDATE_ERRORS_PKW', pkwFieldsErrors);
    },
    switchPDFLogoPosition(state, value) {
      this.commit('SWITCH_PDF_LOGO_POSITION', value);
      this.dispatch('axiosUpdatePDFSettings');
    }
  }
}