import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import MeineReisenView from '../views/MeineReisenView.vue'
import Page404View from '../views/Page404View.vue'

const routes = [
  {
    path: '/',
    name: 'meinereisen',
    component: MeineReisenView,
    beforeEnter: fetchTours
  },
  {
    path: '/reisestationen/:tourID',
    name: 'reisestationen',
    component: () => import('../views/ReisesationenView.vue'),
    beforeEnter: validateTourIDBeforeEnter
  },
  {
    path: '/reisekosten/:tourID',
    name: 'reisekosten',
    component: () => import(/* webpackChunkName: "reisekosten" */ '../views/ReisekostenView.vue'),
    beforeEnter: validateTourIDBeforeEnter
  },
  {
    path: '/pdf-configurator/:tourID',
    name: 'pdf-configurator',
    component: () => import('../views/PDFView.vue'),
    beforeEnter: validateTourIDBeforeEnter
  },
  {
    path: '/tour-overview/:tourID',
    name: 'tour-overview',
    component: () => import('../views/TourOverview.vue'),
    beforeEnter: validateTourIDBeforeEnter
  },

  // Specific 404 path for programmatic navigation
  {
    path: '/404',
    name: 'Page404',
    component: Page404View
  },
  // Catch all other routes for non-existent paths
  {
    path: '/:catchAll(.*)',
    component: Page404View
  }
];

// Before Tours Overview loading:
function fetchTours(to, from, next) {
  try {
    // store.dispatch('fetchToursFake');
    store.dispatch('axiosGet');
    next();
  } catch (error) {
    console.error('Error fetching tours:', error);
  }
}

// BeforeEnter Guard
async function validateTourIDBeforeEnter(to, from, next) {
  const tourID = to.params.tourID;

  // Fetch tours if not loaded
  if (!store.getters.isToursLoaded) {
    try {
      await store.dispatch('axiosGet');
    } catch (error) {
      console.error('Error fetching tours:', error);
      return next({ name: 'Page404' });
    }
  }

  // Set active tour if it's not already set
  if (store.state.userDB.activeTourID !== tourID) {
    store.dispatch('setActiveTourID', { tourID });
  }
  
  // Check if tours exist and tourID is valid + Avoid infinite loop if already on the tour-overview route
  const tours = store.state.userDB.tours;

  if (tours && tours[tourID] !== undefined) {
    if (tours[tourID].tourStatus === 0 || to.name === 'pdf-configurator') {
      return next(); // Proceed if tourID is valid and tourStatus is 'Draft'
    } else if (to.name !== 'tour-overview') {
      // Redirect to tour-overview if status is not 'Draft' and not already on tour-overview
      return next({ name: 'tour-overview', params: { tourID: tourID } });
    } else {
      return next();                  // Prevent further redirection when already on tour-overview
    }
  } else {
    return next({ name: 'Page404' }); // Redirect to 404 page if tourID is invalid
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
