<template>
  <h2>{{ vocabulary.myToursOverview.title }}</h2>
  <div class="tour-container block-container">
    <div class="table-header">
      <div class="table-row">
        <div v-for="(field, key, index) in vocabulary.myToursOverview.tableHeaders" :key="index" :class="['table-value', { 'sorting-value': index === sortingParameterIndex }]" @click="sort(index, field, key)">
          <div class="text">
            <div :class="['sort-icon', { 'active-sorting-parameter': index === sortingParameterIndex }, { 'ascending': ascendingSortOrder } ]">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
              </svg>
            </div>
            {{ field }}
          </div>
        </div>
      </div>
    </div>

    <div class="table-body">
      <div class="no-data" v-if="Object.keys(userDB.tours).length === 0 || !Object.values(userDB.tours).some(tour => tour.tourTitles.title !== '')">
        <div class="icon">
          <svg class="empty-folder" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M384 480l48 0c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224l-400 0c-11.4 0-21.9 6-27.6 15.9L48 357.1 48 96c0-8.8 7.2-16 16-16l117.5 0c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8L416 144c8.8 0 16 7.2 16 16l0 32 48 0 0-32c0-35.3-28.7-64-64-64L298.5 96c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7L64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l23.7 0L384 480z"/>
          </svg>
          <svg class="bugs" xmlns="http://www.w3.org/2000/svg" viewBox="-400 100 1000 1000">
            <path d="M256 0c53 0 96 43 96 96l0 3.6c0 15.7-12.7 28.4-28.4 28.4l-135.1 0c-15.7 0-28.4-12.7-28.4-28.4l0-3.6c0-53 43-96 96-96zM41.4 105.4c12.5-12.5 32.8-12.5 45.3 0l64 64c.7 .7 1.3 1.4 1.9 2.1c14.2-7.3 30.4-11.4 47.5-11.4l112 0c17.1 0 33.2 4.1 47.5 11.4c.6-.7 1.2-1.4 1.9-2.1l64-64c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-64 64c-.7 .7-1.4 1.3-2.1 1.9c6.2 12 10.1 25.3 11.1 39.5l64.3 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c0 24.6-5.5 47.8-15.4 68.6c2.2 1.3 4.2 2.9 6 4.8l64 64c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-63.1-63.1c-24.5 21.8-55.8 36.2-90.3 39.6L272 240c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 239.2c-34.5-3.4-65.8-17.8-90.3-39.6L86.6 502.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l64-64c1.9-1.9 3.9-3.4 6-4.8C101.5 367.8 96 344.6 96 320l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64.3 0c1.1-14.1 5-27.5 11.1-39.5c-.7-.6-1.4-1.2-2.1-1.9l-64-64c-12.5-12.5-12.5-32.8 0-45.3z"/>
          </svg>
        </div>
        <div class="text">{{ vocabulary.myToursOverview.noData }}</div>
      </div>
      <!-- 
      ********************************************************************************************************************************
      <div v-for="tour in sortedTours" :key="tour.tourID" :data-tourid="tour.tourID">{{ tour.tourID + ': ' + tour.tourID + ': ' + tour.grandTotal.toFixed(2) }}</div>
      ********************************************************************************************************************************
      <div v-for="tour in userDB.tours" :key="tour.tourID" :data-tourid="tour.tourID">{{ tour.tourID + ': ' + tour.tourID + ': ' + tour.grandTotal.toFixed(2) }}</div>
      -->
      
      <div :class="[{ 'empty-row': !tour.tourTitles.title }, 'table-row']" v-for="tour in sortedTours" :key="tour.tourID" :data-tourid="tour.tourID">
        <span class="table-value">{{ formatDate(tour.dates.creation) }}</span>
        <span class="table-value">{{ tour.tourTitles.title }}</span>
        <!-- <span class="table-value">{{ tour.tourTitles.employee }}</span> -->
        <span class="table-value">{{ tour.tourTitles.company }}</span>
        <span class="table-value">
          <span class="status-value">
            <span :class="[
              { draft: tour.tourStatus === 0 }, 
              { inprogress: tour.tourStatus === 1 }, 
              { approved: tour.tourStatus === 2 }, 
              { declined: tour.tourStatus === 3 }, 
              { paid: tour.tourStatus === 4 }, 
              'status-mark']"
            >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/>
            </svg> -->
            </span>
            <span class="text">{{ vocabulary.tourStatus[tour.tourStatus] }}</span>
          </span>
        </span>
        <span class="table-value">{{ tour.comment }}</span>
        <span class="table-value">
          <span class="value" 
            @click="goToPDFSettingsDblClick(tour.tourID)"
          >{{ parseFloat(tour.grandTotal).toFixed(2) }} {{ vocabulary.general.currencySign }}</span>
        </span>

        <!-- ACTIONS -->
        <span class="table-value actions">         
          <span class="value">
            <button :class="['actions-menu-btn', { 'active': tour.tourID === actionsMenuActiveIndex }]"
              @click="activateActions(tour.tourID)"
            >
              <!-- @mouseover="activateActions(tour.tourID)"
              @mouseleave="deActivateActions()" -->
              <i class="btn-icon burger">
                <div class="menu-icon">
                  <div class="icon-line line-1"></div>
                  <div class="icon-line line-2"></div>
                  <div class="icon-line line-3"></div>
                </div>
              </i>
              <i class="btn-icon dots">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-150 -100 672 768">
                  <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"/>
                </svg>
              </i>
              <span :class="['actions-btns-container', { 'active': tour.tourID === actionsMenuActiveIndex }]">
                <span class="btns-container">
                  <button class="preview-pdf no-print btn rounded-n-text" @click="tourOverview(tour.tourID)" :title="vocabulary.buttons.tourOverview">
                    <i class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                      </svg>
                    </i>
                    <span class="text">{{ vocabulary.buttons.tourOverview }}</span>
                  </button>
                  <button class="btn rounded-n-text" @click="editTour(tour.tourID)" :title="vocabulary.buttons.edit" :disabled="tour.tourStatus != 0">
                    <i class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/>
                      </svg>
                    </i>
                    <span class="text">{{ vocabulary.buttons.edit }}</span>
                  </button>
                  <button class="btn rounded-n-text" @click="triggerSendForApproval($event, tour.tourID)" :title="vocabulary.buttons.sentforapproval" :disabled="tour.tourStatus != 0">
                    <i class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/>
                      </svg>
                      <!-- 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                          <path d="M192 128c0-17.7 14.3-32 32-32s32 14.3 32 32v7.8c0 27.7-2.4 55.3-7.1 82.5l-84.4 25.3c-40.6 12.2-68.4 49.6-68.4 92v71.9c0 40 32.5 72.5 72.5 72.5c26 0 50-13.9 62.9-36.5l13.9-24.3c26.8-47 46.5-97.7 58.4-150.5l94.4-28.3-12.5 37.5c-3.3 9.8-1.6 20.5 4.4 28.8s15.7 13.3 26 13.3H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H460.4l18-53.9c3.8-11.3 .9-23.8-7.4-32.4s-20.7-11.8-32.2-8.4L316.4 198.1c2.4-20.7 3.6-41.4 3.6-62.3V128c0-53-43-96-96-96s-96 43-96 96v32c0 17.7 14.3 32 32 32s32-14.3 32-32V128zm-9.2 177l49-14.7c-10.4 33.8-24.5 66.4-42.1 97.2l-13.9 24.3c-1.5 2.6-4.3 4.3-7.4 4.3c-4.7 0-8.5-3.8-8.5-8.5V335.6c0-14.1 9.3-26.6 22.8-30.7zM24 368c-13.3 0-24 10.7-24 24s10.7 24 24 24H64.3c-.2-2.8-.3-5.6-.3-8.5V368H24zm592 48c13.3 0 24-10.7 24-24s-10.7-24-24-24H305.9c-6.7 16.3-14.2 32.3-22.3 48H616z"/>
                        </svg>
                      -->
                    </i>
                    <span class="text">{{ vocabulary.buttons.sentforapproval }}</span>
                  </button>
                  <button class="btn rounded-n-text delete" @click="triggerDeleteTour($event, tour.tourID)" :title="vocabulary.buttons.delete" :disabled="tour.tourStatus != 0">
                    <i class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                      </svg>
                    </i>
                    <span class="text">{{ vocabulary.buttons.delete }}</span>
                  </button>
                  <button class="preview-pdf no-print btn rounded-n-text" @click="previewPDF(tour.tourID)" :title="vocabulary.buttons.pdf_preview">
                    <i class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                      </svg>
                    </i>
                    <span class="text">{{ vocabulary.buttons.pdf_preview }}</span>
                  </button>
                  <button class="save-print no-print btn rounded-n-text" @click="downloadPDF(tour.tourID)" :id="tour.tourID" :title="vocabulary.buttons.pdf_download">
                    <i class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39L344 184c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 134.1-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"/>
                      </svg>
                    </i>
                    <span class="text">{{ vocabulary.buttons.pdf_download }}</span>
                  </button>
                  <!-- <button class="btn rounded-n-text pdf-settings" @click="goToPDFSettings(tour.tourID)">
                    <i class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
                      </svg>
                    </i>
                    <span class="text">{{ vocabulary.buttons.pdf_settings }}</span>
                  </button> -->
                </span>
              </span>
            </button>
          </span>
        </span>
      </div>
    </div>
  </div>
  <teleport to="body">
    <PokaYoke ref="pokaYokeRef" />
  </teleport>
</template>

<script>
import { mapGetters } from 'vuex';
import { useFormatDate } from '@/composables/useFormatDate';
import PokaYoke from '@/components/PokaYokeComponent';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'; // This should already include Roboto
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'ToursList',
  data() {
    return {
      // SORTING
      sortingParameterIndex: 0,
      sortingParameters: {
        0: 'dates.creation',      // Index 0 corresponds to 'dates.creation'
        1: 'tourTitles.title',    // Index 1 corresponds to 'tourTitles.title'
        2: 'tourTitles.company', 
        3: 'tourStatus',
        4: 'comment',
        5: 'grandTotal',
      },
      sortBy: 'dates.creation',   // Default parameter to sort by
      ascendingSortOrder: false,  // Can be 'ascending' or 'descending'

      // ACTIONS-MENU
      actionsMenuActiveIndex: '',
      clickTimeout: null,
    }
  },
  components: {
    PokaYoke,
  },
  computed: {
    ...mapGetters([
      'basics',
      'vocabulary',
      'pdfSettings',
      'userDB',
      'activeTour',
      'combinedTotals'
    ]),
    sortingKey() {
      return this.sortingParameters[this.sortingParameterIndex] || 'dates.creation';
    },
    sortedTours() {
      return Object.values(this.userDB.tours).sort((a, b) => {
        let valueA = this.getValueByKey(a, this.sortingKey);
        let valueB = this.getValueByKey(b, this.sortingKey);

        // If the sorting parameter is a date, convert to Date object for comparison
        if (this.sortingKey === 'dates.creation') {
          valueA = new Date(valueA).getTime();
          valueB = new Date(valueB).getTime();
        }

        if (this.ascendingSortOrder) {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else {
          return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        }
      });
    }
  },
  methods: {
    // SORTING
    getValueByKey(obj, key) {
      return key.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    sort(index) {
      if(index === 6) return;

      if(this.sortingParameterIndex === index) {
        this.ascendingSortOrder = !this.ascendingSortOrder;
      } else {
        this.ascendingSortOrder = false;
        this.sortingParameterIndex = index;
      }
    },

    // ACTIONS:
    activateActions(index) {
      if(this.actionsMenuActiveIndex !== index) {
        this.actionsMenuActiveIndex = index;
      } else {
        this.actionsMenuActiveIndex = '';
      }
    },
    deActivateActions() {
      this.actionsMenuActiveIndex = '';
    },
    triggerDeleteTour($event, tourID) {
      this.$refs.pokaYokeRef.deleteTourPokaYoke($event, tourID);
    },
    triggerSendForApproval($event, tourID) {
      this.$store.dispatch('setActiveTourID', { tourID });
      this.$refs.pokaYokeRef.sendForApprovalPokaYoke($event, tourID);
    },
    formatDate(aDate) {
      return useFormatDate(aDate);
    },
    tourOverview(tourID) {
      this.$store.dispatch('setAppTourEditingMode', true);
      this.$router.push({ name: 'tour-overview', params: { tourID: tourID } });                   // redirect to the home page
    },
    editTour(tourID) {
      this.$store.dispatch('setActiveTourID', { tourID });
      this.$store.dispatch('setAppTourEditingMode', true);                                        // to avoid the DatesTable recalculations as long as no changes in Stations has been made
      this.$router.push({ name: 'reisestationen', params: { tourID: tourID } });                  // redirect to the home page
    },
    previewPDF(tourID) {
      // this.$store.dispatch('setAppTourEditingMode', true);
      this.$store.dispatch('setActiveTourID', { tourID });
      this.$store.dispatch('downloadPDFVuex', { tourID, mode: 'preview' } );
    },
    downloadPDF(tourID) {
      // this.$store.dispatch('setAppTourEditingMode', true);
      this.$store.dispatch('setActiveTourID', { tourID });
      this.$store.dispatch('downloadPDFVuex', { tourID, mode: 'download' });
    },
    goToPDFSettings(tourID) {
      this.$store.dispatch('setAppTourEditingMode', true);
      this.$store.dispatch('setActiveTourID', { tourID });
      this.$router.push({ name: 'pdf-configurator', params: { tourID: tourID } });
    },
    goToPDFSettingsDblClick(tourID) {
      // Check if there's an existing click timeout
      if (this.clickTimeout) {
        // If a second click occurs before the timeout, clear the timeout and execute the logic
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;

        // Proceed with the logic for the double-click
        this.$store.dispatch('setAppTourEditingMode', true);
        this.$store.dispatch('setActiveTourID', { tourID });
        this.$router.push({ name: 'pdf-configurator', params: { tourID: tourID } });
      } else {
        // If it's the first click, set a timeout to wait for the second click
        this.clickTimeout = setTimeout(() => {
          // If no second click occurs before the timeout, clear the clickTimeout
          this.clickTimeout = null;
        }, 200); // Adjust the delay as needed (300ms is a common threshold for double-clicks)
      }
    },
  }
}
</script>

<style scoped lang="scss">
$bg-color-menu-btns: $main-color;
$text-color-menu-btns: white;

.tour-container {
  box-shadow: $box-shadow;

  .table-header {
    font-weight: 700;
    background-color: $text-color-white;
    border-bottom: $table-header-bottom-border;

    .table-value {
      transition: $transition;

      &:not(:nth-child(7)) {
        cursor: pointer;
      }

      &:not(:last-child):hover {
        color: $accent-color;
      }

      &.sorting-value {
        &:nth-child(5) {
          overflow: visible;
          @include respond-to(display, (
            xxs: none,
            xl: flex
          ));
        }
      }

      .text {
        position: relative;

        //SORTING
        .sort-icon {
          opacity: 0;
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, -65%);
  
          &.active-sorting-parameter {
            display: flex;
            justify-content: center;
            align-items: baseline;
            width: 22px;
            height: 18px;
            opacity: 1;
            transition: $transition;
          }
  
          &.ascending {
            transform: translate(-50%, -50%) rotate(180deg);
            transform-origin: 50%;
          }
  
          svg {
            fill: $text-color;
          }
        }
      }
    }
  }

  .table-row {
    display: grid;
    background-color: $text-color-white;
    @include respond-to(grid-template-columns, (
      xxs: repeat(14, 1fr),
      l: repeat(12, 1fr),
      xl: repeat(14, 1fr)
    ));
    @include respond-to(padding, (
      xxs: 15px 20px,
      xl: 30px 40px
    ));
    @include respond-to(gap, (
      xxs: $gap,
      xl: $gap * 1.5
    ));

    &.empty-row {
      display: none;
    }
  }

  .table-body {
    margin-bottom: 40px;
    
    .no-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 240px;
      background-color: $text-color-white;

      .icon {
        position: relative;
        height: 80px;
        width: 80px;
        z-index: 2;

        svg {
          position: absolute;
          fill: $text-color;

          &.empty-folder {
            top: 20px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 60px;
          }

          &.bugs {
            top: -20px;
            left: 50%;
            width: 30px;
            transform: rotate(45deg);
          }
        }
      }

      .text {
        margin-block: $gap;
      }
    }

    .table-row {
      // margin-bottom: 80px;
      // border-bottom: $table-body-bottom-border;
      @include respond-to(padding, (
        xxs: 10px 20px,
        xl: 20px 40px
      ));
      @include respond-to(gap, (
        xxs: $gap,
        xl: $gap * 1.5
      ));

      &:nth-child(odd) {
        background-color: rgba($main-color, 0.02);
      }


      &:hover {
        background-color: rgba($main-color, 0.1);
        box-shadow: $box-shadow;
        transition: $transition;

        // .btn {
        //   background-color: $text-color-white;

        //   svg {
        //     fill: $main-color;
        //   }
        // }
      }
    }

    .status-value {
      display: flex;
      align-items: center;
      
      .text {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include respond-to(width, (
          xxs: 80px,
          s: unset
        ));
      }

      .status-mark {
        margin-right: ($gap * 0.5);
        flex: 0 0 7px;
        width: 7px;
        height: 7px;
        margin-bottom: 4px;
        border-radius: 50px;
  
        &.draft {
          background-color: rgba($map-dotted-line,0.9);
          box-shadow: 0 0 2px 1px rgba($map-dotted-line,0.9);

          // svg {
          //   fill: rgba($map-dotted-line, 0.9);
          //   box-shadow:  0px 0px 2px 1px rgba($map-dotted-line,0.9);
          //   -webkit-box-shadow:  0px 0px 2px 1px rgba($map-dotted-line,0.9);
          //   -moz-box-shadow:  0px 0px 2px 1px rgba($map-dotted-line,0.9);
          // }
        }
        &.inprogress {
          background-color: rgba($in-progress,0.9);
          box-shadow: 0 0 2px 1px rgba($in-progress,0.9);
        }
        &.approved {
          background-color: rgba($tour-approved,0.9);
          box-shadow: 0 0 2px 1px rgba($tour-approved,0.9);
        }
        &.declined {
          background-color: rgba($warning-color,0.9);
          box-shadow: 0 0 2px 1px rgba($warning-color,0.9);
        }
        &.paid {
          background-color: rgba($approved,0.9);
          box-shadow: 0 0 2px 1px rgba($approved,0.9);
        }
      }
    }
  }

  .table-value {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: left;
    @include respond-to(grid-column, (
      l: span 3,
      xl: span 2
    ));

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6) {
      @include respond-to(grid-column, (
        xxs: span 4,
        m: span 3,
        l: span 2,
        xl: span 2
      ));
    }

    &:last-child,
    &:nth-child(6) {
      background-color: transparent;
      justify-content: end;
    }

    // Comment 
    &:nth-child(5) {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
    }

    // &:nth-child(5)::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   width: 30px;
    //   background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
    // }

    &:nth-child(5):hover {
      white-space: normal;
    }

    &:nth-child(5):hover::after {
      content: none;
    }

    &:nth-child(3) {
      @include respond-to(display, (
        xxs: none,
        xl: flex
      ));
    }

    &:nth-child(7) {
      @include respond-to(visibility, (
        xxs: hidden,
        m: visible
      ));
      @include respond-to(grid-column, (
        xxs: span 2,
        l: span 3,
        xl: span 2
      ));

      &.actions {
        display: flex;
        visibility: visible;

        .value {
          .actions-menu-btn {
            position: relative;
            cursor: pointer;

            &.active {
              .btn-icon {

                &.dots {
                  rotate: 0deg;
                }

                &.burger {
                  background-color: $bg-color-menu-btns;
                  box-shadow: 0 0 16px 1px rgba(123, 123, 123, 0.7);

                  .menu-icon {
                    .icon-line {
                      background-color: $text-color-menu-btns;
                      transform-origin: 50%;

                      &:nth-child(1) {
                        transform-origin: top;
                        transform: translate(0px, 6px) rotatez(45deg);
                      }

                      &:nth-child(2) {
                        transform-origin: bottom;
                        transform: rotatez(-45deg);
                        width: 20px;
                      }

                      &:nth-child(3) {
                        opacity: 0;
                      }
                    }
                  }
                }
              }
            }

            .btn-icon {
              width: 30px;

              &.dots {
                transform-origin: 50% 50%;
                fill: rgba($main-color, 1);
                rotate: 90deg;
                width: 20px;
                height: 20px;
                transition: $transition;
                transition-delay: 0.1s;
                @include respond-to(display, (
                  xxs: flex,
                  xl: none
                ));

                svg {
                  width:  100%;
                  height: 100%;
                  fill: rgba($main-color, 1);
                }
              }

              &.burger {
                display: flex;
                justify-items: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                transition: $transition;
                transition-delay: 0.1s;
                @include respond-to(display, (
                  xxs: none,
                  xl: flex
                ));

                .menu-icon {               
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  width: 30px;
                  height: 14px;
                  box-sizing: border-box;
                  transition: $transition;
                  cursor: pointer;

                  .icon-line {
                    width: 20px;
                    height: 2px;
                    background-color: $main-color;
                    opacity: 1;
                    transition: $transition;
                    transition-delay: 0.07s;


                    &:nth-child(2) {
                      width: 14px;
                      transform: translate(3px, 0);
                    }
                  }
                }
              }
            }
          }
        }

        
        // @media screen and (max-width: #{map-get($breakpoints, m)}#{$sizing}) {
        //   .actions-menu-btn {
        //     background-color: unset;
            
        //     .menu-btn-icon {
        //       display: flex;
        //     }
        //   }
        // }
      }
    }

    &:nth-child(5) {
      margin: auto 0;
      @include respond-to(display, (
        xxs: none,
        xl: block
      ));
    }

    &:nth-child(1) {
      // text-align: left;
      @include respond-to(display, (
        xxs: none,
        m: flex,
      ));
    }

    &.actions {
      position: relative;

      .actions-btns-container {
        display: none;
        cursor: pointer;

        &.active {
          position: absolute;
          top: -2px;
          left: -($gap * 0.5);
          transform: translateX(-100%);
          display: flex;
          flex-direction: column;
          min-width: 180px;
          background-color: $bg-color-menu-btns;
          box-shadow: $box-shadow;
          border-radius: $border-radius;
          z-index: 2;
        }

        button {
          display: flex;
          justify-content: start;
          width: 100%;
          
          &:hover {
            &:enabled{
              transform: translateX(6px);
            }
          }

          .btn-icon {
            width: 12px;
            height: 14px;

            svg {
              fill: $text-color-menu-btns;
            }
          }
          
          .text {
            color: $text-color-menu-btns;
          }
        }
      }
    }
  }

  // .bottom-btn {
  //   &:disabled {
  //     cursor: not-allowed;
  //   }
  // }

  .btns-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    grid-column: span 12;
    gap: ($gap / 1.5);
    margin-top:    ($gap * 1);
    margin-bottom: ($gap * 1);

    // .btn {
    //   &.rounded-n-text {
    //     &.pdf-settings {
    //       .text {
    //         color: $bg-color-menu-btns;
    //       }
    //     }
    //   }
    // }
  }

  // .divider {
  //   letter-spacing: 2px;
  //   color: $main-color;
  //   font-size: 16px;
  //   font-weight: 700;
  //   margin-top: 9px;
  //   border-bottom: 1px dotted $main-color;
  //   text-align: left;
  //   padding-left: 16px;
  // }
}
</style>