<template>
  <header class="header no-print">
    <div class="header-content">
      <router-link to="/" class="logo">
        <i class="map-icon">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
          </svg> -->

          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
          </svg> -->
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0z"/>
            <svg viewBox="-140 -150 560 940" xmlns="http://www.w3.org/2000/svg">
              <path fill="#004072" d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"/>
            </svg>
          </svg> -->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path d="M381 114.9L186.1 41.8c-16.7-6.2-35.2-5.3-51.1 2.7L89.1 67.4C78 73 77.2 88.5 87.6 95.2l146.9 94.5L136 240 77.8 214.1c-8.7-3.9-18.8-3.7-27.3 .6L18.3 230.8c-9.3 4.7-11.8 16.8-5 24.7l73.1 85.3c6.1 7.1 15 11.2 24.3 11.2l137.7 0c5 0 9.9-1.2 14.3-3.4L535.6 212.2c46.5-23.3 82.5-63.3 100.8-112C645.9 75 627.2 48 600.2 48l-57.4 0c-20.2 0-40.2 4.8-58.2 14L381 114.9zM0 480c0 17.7 14.3 32 32 32l576 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 448c-17.7 0-32 14.3-32 32z"/>
          </svg>
        </i>
        <h1>Reisekostenabrechnung</h1></router-link>
      <div tabindex="0" :class="[{ active: menuActive }, 'menu-icon']" @keydown.enter="toggleMenu()" @click="toggleMenu()">
        <div class="icon-line line-1"></div>
        <div class="icon-line line-2"></div>
        <div class="icon-line line-3"></div>
      </div>
    </div>
  </header>
  <div :class="[{ active: menuActive }, 'menu-container']">
    <div class="menu-content">
      <LanguageSelector />
    </div>    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSelector from '@/components/LanguageSelector.vue';

export default {
  name: 'HeaderComponent',
  data() {
    return {
      menuActive: false
    }
  },
  computed: {
    ...mapGetters([
      'basics',
      'localization',
      'vocabulary',
    ]),
  },
  components: {
    LanguageSelector
  },
  methods: {
    toggleMenu() {
      if (!this.menuActive) {
        this.addBodyFixed();
        this.setMainMenuEventListener();
      } else {
        this.closeDropdown();
        this.removeMainMenuEventListener();
      }
    },
    
    handleClickOutsideOfMainMenu($event) {
      if (this.menuActive && (!$event.target.closest('.' + 'menu-container') && !$event.target.closest('header'))) {
        this.closeDropdown();
        this.removeMainMenuEventListener();
      }
    },
    setMainMenuEventListener() {
      document.addEventListener('click', this.handleClickOutsideOfMainMenu);
      // document.querySelector('html').classList.toggle('prevent-scrolling');
    },
    removeMainMenuEventListener() {
      document.removeEventListener('click', this.handleClickOutsideOfMainMenu);
      // this.menuActive = false;
    },

    //Handle Scrolling freeze of the website while the Menu/Pop-Up is open: prevent scrolling event
    addBodyFixed() {
      this.menuActive = true;
      document.querySelector('html').classList.add('prevent-scrolling');      
    },
    closeDropdown() {
      this.menuActive = false;
      document.querySelector('html').classList.remove('prevent-scrolling');
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutsideOfMainMenu);
  }
}
</script>

<style scoped lang="scss">
$menu_line_size: 2px;
$menu_height: 20px;

header {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: $main-color;
  margin: 0 auto 0;
  z-index: 4;

  &:has(.pdf) {
    max-width: unset;
  }

  .header-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $max-width;
    padding-block: $rand-padding;
    color: $text-color-white;
    @include respond-to(padding-inline, (
      xxs: ($rand-padding * 0.25),
      xs: ($rand-padding * 0.5),
      l: ($rand-padding * 1.5),
      xxxl: ($rand-padding * 1)
    ));

    .logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $text-color-white;
      cursor: pointer;

      .map-icon {
        display: flex;
        height: 25px;
        @include respond-to(width, (
          xxs: 20px,
          l: 22px
        ));

        svg {
          fill: rgba($text-color-white, 1);
          // stroke: 1px solid rgba($text-color, 1);
        }
      }
    }

    .menu-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: $menu_height;
      box-sizing: border-box;
      cursor: pointer;

      .icon-line {
        width: 30px;
        height: $menu_line_size;
        background-color: $text-color-white;
        transition: .35s  cubic-bezier(0.7, -0.5, 0.3, 1.5);
        opacity: 1;
      }

      &.active {
        .icon-line {

          &:nth-child(1) {
            transform-origin: top;
            transform: translate(0px, 9px) rotatez(45deg);
          }

          &:nth-child(2) {
            transform-origin: bottom;
            transform: rotatez(-45deg);
          }
          
          &:nth-child(3) {
            opacity: 0;
            transform: translate(0px, 20px)
          }
        }
      }
    }
  }
}

.menu-container {
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  padding: $rand-padding 0;
  background-color: $main-color;
  border-top: 1px dotted $text-color-white;
  color: $text-color-white;
  transition: $transition;
  z-index: 3;

  &.active {
    top: calc(($rand-padding * 2) + $menu_height);
  }

  .menu-content {
    display: flex;
    justify-content: end;
    width: 100%;
    max-width: $max-width;
    @include respond-to(padding-inline, (
      xxs: $rand-padding ($rand-padding * 0.25),
      xs: $rand-padding ($rand-padding * 0.5),
      l: $rand-padding ($rand-padding * 1.5),
      xxxl: $rand-padding ($rand-padding * 1)
    ));

    .lang-switcher {
      margin: 6px;
      font-weight: 700;

      .language-btn {
        cursor: pointer;
        font-weight: 700;
        font-size: 1.2rem;
        margin: 0 6px;
        padding: 4px;
        color: $text-color-white;
        text-decoration: none;
        transition: $transition;

        &:hover {
          text-decoration: underline;
        }
      }

      .active-lang {
        background-color: white;
        color: $text-color;
      }
    }
  }
}

</style>
