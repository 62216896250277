export function useFormatDate(dateString) {
  // If no argument is passed, use the current date
  const today = dateString ? new Date(dateString) : new Date();
  // console.log('today: ', today);

  // Check if the conversion was successful
  if (isNaN(today.getTime())) {
    console.error("Invalid date string:", dateString);
    return null; // Handle the error as needed
  }

  return `${today.getDate() < 10 ? '0' : ''}${today.getDate()}.${(today.getMonth() + 1) < 10 ? '0' : ''}${today.getMonth() + 1}.${today.getFullYear()}`;
}