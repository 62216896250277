<template>
  <ToursList />

  <div class="bottom-btn-container">
    <button class="bottom-btn btn" @click="addNewTour">
      {{ vocabulary.buttons.addTour }}
      <i class="btn-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm16 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H368c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16V384h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H448V304z"/>
        </svg>
      </i>
    </button>
  </div>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import ToursList  from '@/components/ToursList.vue';

  export default {
    name: 'MeineReisen',
    components: {
      ToursList
    },
    computed: {
      ...mapGetters([
        'vocabulary',
        'userDB',
      ])
    },
    mounted() {
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    },
    methods: {
      async addNewTour() {
        this.$store.dispatch('setAppTourEditingMode', false);
        this.$store.dispatch('setAppNewTourCreatingMode', true);

        // try {
          let uuID = await this.$store.dispatch('createUniqueTourID');
          // console.log('uuID returned: ', uuID);
          this.$store.dispatch('createNewDraftTour', { tourID: uuID });
          // await this.$nextTick();
          this.$router.push({ name: 'reisestationen', params: { tourID: uuID } });
        // } catch(err) {
        //   console.error('Error creating new tour: ', err);
        // }
      },  
    }
  }
</script>

<style scoped lang="scss">
</style>