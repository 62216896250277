<template>
  <div v-if="popUpShow" class="poka-yoke">
    <div class="poka-yoke-content">

      <!-- PopUp for DELETE -->
      <template v-if="popUpDeleteActive">
        <p class="text">{{ vocabulary.pokaYoke.deleteTourText }}</p>
        <div class="buttons-container">
          <button class="btn" @click="popUpDeleteActive = false, removePokaYokeEventListener()">
            <i class="btn-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
              </svg>
            </i>
            <span class="text">{{ vocabulary.buttons.abort }}</span>
          </button>
          <button class="btn red" @click="popUpDeleteActive = false, removePokaYokeEventListener(), deleteTour()">
            <i class="btn-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
              </svg>
            </i>
            <span class="text">{{ vocabulary.buttons.delete }}</span>
          </button>
        </div>
      </template>

      <!-- PopUp for APPROVAL -->
      <template v-if="popUpSenForApprovalActive">
        <h2 class="terms-title">
          {{ vocabulary.pokaYoke.approvalTitle }}
          <i class="terms-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
              <path d="M64.9 96C67.1 84.4 73.7 76.2 86 70.6c13.8-6.2 34.8-8.9 61.2-4.5c8.8 1.4 36.1 7.1 44.1 9.3c17 4.8 34.7-5.1 39.5-22.2s-5.1-34.7-22.2-39.5c-11.1-3.1-41-9.2-50.9-10.8C123-2.7 88.3-.6 59.7 12.3C29.9 25.8 7.5 50.9 1.6 86.5c-.1 .5-.2 1.1-.2 1.6c-2.2 19.7 .3 37.9 8.1 54.1c7.7 16.1 19.4 28 32 36.9c.6 .5 1.3 .9 2 1.4C22.3 194.2 6.5 215.1 1.7 243c-.1 .6-.2 1.1-.2 1.7c-2.3 19.3 .4 37.1 8.4 53c7.9 15.6 19.8 27 32.3 35.5c22.4 15.2 51.9 24 75.4 31l0 0 3.7 1.1c27.2 8.2 46.9 14.6 59.4 23.8c5.5 4 8.2 7.6 9.5 10.9c1.3 3.2 2.6 8.6 .9 18.1c-1.7 10.1-7.7 18-20.7 23.5c-14 6-35.4 8.5-62 4.4c-12.8-2.1-35.1-9.7-54.1-16.2l0 0c-4.3-1.5-8.5-2.9-12.3-4.2C25.3 420 7.2 429.1 1.6 445.8s3.5 34.9 20.3 40.5c2.6 .8 5.7 1.9 9.2 3.1c18.6 6.3 48.5 16.6 67.3 19.6l0 0 .2 0c34.5 5.4 68.8 3.4 97.2-8.7c29.4-12.6 52.5-36.5 58.5-71.5c3.3-19.3 1.9-37.4-5-53.9c-6.3-15-16.4-26.4-27.6-35.2c16.5-13.9 28.5-33.2 32.6-58.2c3.2-19.8 1.9-38.3-4.8-55.1c-6.7-16.8-17.8-29.4-30.2-39c-22.8-17.6-53.6-27.4-77.7-35l-1.4-.5c-27.4-8.7-47.8-15.3-61.5-25c-6.1-4.4-9.5-8.5-11.4-12.4c-1.8-3.7-3.2-9.3-2.3-18.5zm76.7 208.5c-.2-.1-.4-.1-.6-.2l-1.4-.4c-27.4-8.2-47.9-14.5-61.7-23.8c-6.2-4.2-9.3-7.9-11-11.3c-1.5-3-2.9-7.7-2.1-15.7c1.9-9.7 7.9-17.3 20.5-22.7c14-6 35.4-8.5 62.1-4.3l16.4 2.6c6.3 2.9 11.7 6 16.2 9.5c5.5 4.2 8.4 8.2 10 12.2c1.6 4 2.8 10.4 1.1 20.9c-2.4 14.7-12.8 26.4-37.1 31l-12.4 2.3z"/>
            </svg>
          </i>
        </h2>
        <div class="input-container">
          <label class="text" for="warningText">
            <ul>
              <li v-for="(text, index) in vocabulary.pokaYoke.warningTexts" :key="index">{{ text }}</li>
            </ul>
          </label>
          <input type="checkbox" name="warningText" id="warningText" :checked="activeTour.checkboxes.mainTerms" @click="toggleMainTerms($event)">
        </div>
        <SignaturePad ref="signaturePadRef" />
        <div class="buttons-container">
          <button class="btn" @click="popUpSenForApprovalActive = false, removePokaYokeEventListener()">
            <i class="btn-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
              </svg>
            </i>
            <span class="text">{{ vocabulary.buttons.abort }}</span>
          </button>
          <button 
            class="btn green" 
            :disabled="!activeTour.checkboxes.mainTerms"
            @click="popUpSenForApprovalActive = false, removePokaYokeEventListener(), sendForApproval()" 
            >
            <i class="btn-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/>
              </svg>
            </i>
            <span class="text">{{ vocabulary.buttons.toApproval }}</span>
          </button>
          <!-- <div class="input-container">
            <label class="text" for="min25kmText">{{ vocabulary.pokaYoke.min25kmText }}</label>
            <input type="checkbox" name="min25kmText" id="min25kmText" class="approval-date" v-model="distance25kmCheck">
          </div>
          <div class="input-container">
            <label class="text" for="min3hoursText">{{ vocabulary.pokaYoke.min3hoursText }}</label>
            <input type="checkbox" name="min3hoursText" id="min3hoursText" class="approval-date" v-model="min3HoursCheck">
          </div> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SignaturePad from '@/components/SignatureComponent';

export default {
  name: 'PokaYoke',
  components: { SignaturePad },
  data() { 
    return { 
      popUpShow: false,
      popUpDeleteActive: false,
      popUpSenForApprovalActive: false,
      mainTerms: false,
      // paragraph8Check: false,
      // distance25kmCheck: false,
      // min3HoursCheck: false,
    }
  },
  beforeUnmount() {
    //just in case: remove all the EventListeners while DOM is being rerendered by VueJS
    document.removeEventListener('click', this.handleClickOutsideOfPokaYoke);
  },
  computed: {
    ...mapGetters([
      'vocabulary',
      'activeTour'
    ]),
  },
  methods: {
    handleClickOutsideOfPokaYoke($event) {
      // if (this.popUpShow && !$event.target.classList.contains('poka-yoke-content')) {
      if (this.popUpShow && !$event.target.closest('.' + 'poka-yoke-content')) {
        this.popUpDeleteActive = false;
        this.popUpSenForApprovalActive = false;
        this.removePokaYokeEventListener();
      }
    },
    setPokaYokeEventListener() {
      document.addEventListener('click', this.handleClickOutsideOfPokaYoke);
      // document.querySelector('html').classList.toggle('prevent-scrolling');
    },
    removePokaYokeEventListener() {
      document.removeEventListener('click', this.handleClickOutsideOfPokaYoke);
      this.popUpShow = false;
      // document.querySelector('html').classList.toggle('prevent-scrolling');
    },
    deleteTourPokaYoke($event, tourID) {
      this.$store.dispatch('setActiveTourID', { tourID });
      this.setPokaYokeEventListener();                                  // Call the method correctly with ()
      $event.stopPropagation();                                         // like preventDefault but in this case we prevent the click to bubble
      this.popUpShow = true;
      this.popUpDeleteActive = true;
    },
    deleteTour() {
      this.$store.dispatch('axiosDelete');                              // Delete Tour from User DB
      this.popUpDeleteActive = false;                                   // close the popup dialog
    },

    toggleMainTerms($event) {
      // console.log('checked triggerred toggleMainTermsAgreement: ', $event.target.checked);
      this.$store.dispatch('toggleMainTermsAgreement', { status: $event.target.checked } );
    },

    sendForApprovalPokaYoke($event, tourID) {
      this.$store.dispatch('setActiveTourID', { tourID });
      this.setPokaYokeEventListener();                                  // Call the method correctly with ()
      $event.stopPropagation();                                         // like preventDefault but in this case we prevent the click to bubble
      this.popUpShow = true;
      this.popUpSenForApprovalActive = true;
    },
    async sendForApproval() {                                                 // Send Tour for Approval + PDF Document
      // Save Signature for that PDF:
      this.$refs.signaturePadRef.saveSignature();
      let newStatus = 1;

      this.$store.dispatch('updateTourStatus', { newStatus });
      this.$store.dispatch('updateSentForApprovalDate');
      await this.$store.dispatch('axiosUpdateTour', { tourData: this.$store.state.userDB.tours[this.$store.state.webSiteModule.basics.activeTourID] }); 

      this.popUpSenForApprovalActive = false;

      // Create a Toast Message
      this.$store.dispatch('addToastMessages', {
        messageTitle: this.vocabulary.toastMessageTexts.messageType2,
        messageDescription: this.vocabulary.toastMessageTexts.sentforapproval,
        messageType: this.vocabulary.toastMessageTexts.messageType2 
      });

      // SEND PDF to DB:
      let tourID = this.$store.state.userDB.tours[this.$store.state.webSiteModule.basics.activeTourID].tourID;
      this.$store.dispatch('downloadPDFVuex', { tourID, mode: 'sendForApproval' } );

      //There was a BUG needed to be fixed first (in mutation ’CREATE_NEW_TOUR’ - the activeTourID mus be set first because the activeTour is determined by the INDEX in the userDB.tours array and not the tourID value)
    },
  }
}
</script>

<style scoped lang="scss">
.poka-yoke {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 600px;
  background-color: rgba($map-dotted-line, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 4;
  @include respond-to(padding, (
    xxs: ($gap * 1),
    xl: ($gap * 6) ($gap * 2)
  ));

  .poka-yoke-content {
    // position: absolute;
    // left: 50%;
    // transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: $gap;
    width: 100%;
    min-width: 300px;
    max-width: 800px;
    background-color: rgba($main-bg, 1);
    // backdrop-filter: blur(8px);
    // -webkit-backdrop-filter: blur(8px);
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    @include respond-to(padding, (
      xxs: ($gap * 1),
      xl: ($gap * 2)
    ));
    // @include respond-to(top, (
    //   xxs: 40px,
    //   xl: 10vh
    // ));

    .terms-title {
      display: flex;
      align-items: center;
      gap: ($gap * 0.5);

      .terms-icon {
        display: flex;
        height: 20px;
        width: 20px;
        order: -1;
        
        svg {
          fill: rgba($text-color, 1);
        }
      }
    }

    ul {
      position: relative;
      list-style-type: auto;
      padding-left: ($gap * 2);
      
      &::before {
        content: "";
        top: -6px;
        height: 100%;
        left: ($gap * 0.4);
        position: absolute;
        border-left: 1px solid rgba($main-color, 1);
      }

      li {
        margin-bottom: ($gap * 1);
      }
    }
    
    .buttons-container {
      display: inline-flex;
      gap: $gap;
      margin-top: ($gap * 1);
      @include respond-to(justify-content, (
      xxs: center,
      l: end
    ));
    }

    .input-container {
      display: flex;
      align-items: start;
      gap: $gap;
      margin-top: ($gap * 1);
      text-align: left;
      @include respond-to(margin-left, (
        xxs: 16px,
        xl: 36px
      ));
      
      // &:nth-child(1) {
      //   max-height: 54px;
      //   border: dotted var(--raisin) 1px;
      //   border-radius: 6px;
      //   padding: var(--padding-main);
      //   overflow-y: scroll;
      // }

      label {
        line-height: 18px;
        display: flex;
        align-items: top;
        padding-inline: ($gap * 0.75);
        @include respond-to(font-size, (
          xxs: 12px,
          xl: 14px
        ));
      }
      
      input {
        position: absolute;
        margin-top: 3px;
        // @include respond-to(left, (
        //   xxs: ($gap * 1),
        //   xl: ($gap * 2)
        // ));
      }
    }
  }
}
</style>