<template>
  <div class="language-selector" >
    <div 
      class="dropdown-header" 
      tabindex="0" 
      @keydown.enter="toggleDropdown"
      @keydown.esc="closeDropdown()"
      @mouseover="handleMouseOver()" 
      @mouseleave="handleMouseLeave()"
      @click="toggleDropdown" 
    >
      <span class="selected-language">
        <span v-html="getFlagCode(localization.UIActiveLanguageCode)" class="flag"></span>
        {{ localization.UIActiveLanguage ? localization.UIActiveLanguage : vocabulary.general.language }}
      </span>
      <i :class="{'arrow-up': isDropdownOpen, 'arrow-down': !isDropdownOpen}"></i>
      <ul :class="[{ 'dd-open': isDropdownOpen }, 'dropdown-list']" tabindex="-1">
        <li 
          tabindex="0"
          v-for="(language, index) in localization.UILanguageOptions" 
          :key="index" 
          :class="{'selected': language.code === localization.UIActiveLanguageCode }"
          @click="switchLanguage(language)" 
          @keydown.enter="switchLanguage(language)"
        >
          <!-- <img :src="language.flag" alt="" class="flag"/> -->
          <span v-html="language.flag" class="flag"></span>
          {{ language.text }}
        </li>
      </ul>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguageSelector',
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'basics',
      'localization',
      'vocabulary',
    ]),
  },
  methods: {
    toggleDropdown() {
      if (this.basics.isTouchDevice) {
        this.isDropdownOpen = !this.isDropdownOpen;
      }
    },
    openDropdown() {
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    getFlagCode(country) {
      return this.$store.getters.getFlagCode(country);
    },
    switchLanguage(language) {
      this.$store.commit('SWITCH_LANGUAGE', language);

      // if (this.basics.isTouchDevice) {
      //   setTimeout(() => {
      //     this.closeDropdown();
      //   }, 100);  // 100 milliseconds delay
      // }
    },
    handleMouseOver() {
      if (!this.basics.isTouchDevice) {
        this.openDropdown();
      }
    },
    handleMouseLeave() {
      this.closeDropdown();
    },
  }
}
</script>

<style scoped lang="scss">
$li-height: 40px;

.language-selector {
  position: relative;
  width: 200px;

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ($gap * 0.5);
    border: 1px solid $main-bg;
    // background-color: $main-bg;
    // border-radius: $border-radius;
    cursor: pointer;

    .selected-language {
      display: flex;
    }

    span {
      color: $text-color-white;
    }

    .arrow-down,
    .arrow-up {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(0,-20px);
        right: 100%;
        display: inline-block;
        padding: 2px;
        width: 8px;
        height: 8px;
        border: 1px solid $text-color-white;
        border-width: 0 2px 2px 0;
        transition: $transition;
        transform-origin: top center;
      }
    }

    .arrow-down::after {
      transform: rotate(45deg) translateY(-50%);
    }

    .arrow-up::after {
      transform: rotate(-135deg) translateY(-50%);
    }
  }

  ul {
    transition: $transition;
    overflow: hidden;
    padding-top: 8px;

    &.dropdown-list {
      position: absolute;
      top: -8px;
      transform: translateY(50%);
      left: 0;
      width: 100%;
      border-radius: $border-radius;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      height: 0px;
      opacity: 0;

      li {
        display: flex;
        align-items: center;
        padding: ($gap * 0.5);
        color: $text-color;
        transition: $transition;
        background-color: $main-bg;
        height: 0px;
        opacity: 0;
        overflow: hidden;
        cursor: pointer;
        @for $i from 1 through 12 {
          &:nth-child(#{$i}) { transition: 
            opacity #{$i * $menu_transition}, 
            height #{$i * $menu_transition}, 
            background-color #{$menu_transition / 1.5}, 
            padding-left #{$menu_transition / 1.5};
        }}

        &:last-child {
          border-radius: 0 0 $border-radius $border-radius;
        }
        
        &:first-child {
          border-radius: $border-radius $border-radius 0 0;
        }

        &:hover {
          background-color: $table-header-bg;
          // padding-left: ($gap * 0.75);
          
          &:last-child {
            border-radius: 0 0 $border-radius $border-radius;
          }
          &:first-child {
            border-radius: $border-radius $border-radius 0 0;
          }
        }

        &.selected {
          position: relative;
          // background-color: $section-line;

          &:last-child {
            border-radius: 0 0 $border-radius $border-radius;
          }
          &:first-child {
            border-radius: $border-radius $border-radius 0 0;
          }

          &::after {
            content: '';
            position: absolute;
            right: 10px;
            display: inline-block;
            padding: 2px;
            width: 4px;
            height: 10px;
            border: 1px solid $approved;
            border-width: 0 2px 2px 0;
            transition: $transition;
            transform: rotate(45deg);
          }
        }
      }
    }

    &.dd-open {
      height: calc($li-height * 2 + 8px);
      opacity: 1;
      
      li {
        height: $li-height;
        opacity: 1;
        // visibility: visible;
      }
    }
  }

  .flag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 16px;
    margin-right: 10px;
    // border-radius: 100px;
    overflow: hidden;
  }
}
</style>
