<template>
  <div :class="{'portrait': basics.isPortrait, 'landscape': !basics.isPortrait}">
    <HeaderComponent />
    <!-- <nav class="no-print">
      <router-link to="/">Reisesationen</router-link>
      <span class="link-icon-container">
        <div class="rect1"></div>
        <div class="rect2"></div>
      </span>
      <router-link to="/reisekosten" class="disabled">Reisekosten</router-link>
      <span class="link-icon-container">
        <div class="rect1"></div>
        <div class="rect2"></div>
      </span>
      <router-link to="/meinereisen">Meine Reisen</router-link>
    </nav> -->
    <main :class="[{ 'view-only': basics.appTourViewMode }]">
      <router-view/>
    </main>
    <ToastComponent />
    <!-- <FooterComponent /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderComponent from '@/components/HeaderComponent.vue'
import ToastComponent from '@/components/ToastComponent.vue';
// import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    ToastComponent
    // FooterComponent
  },
  computed: {
    ...mapGetters([
      'basics',
    ])
  },
  async beforeMount() {
    // await this.$store.dispatch('axiosGet');
    this.$store.dispatch('updateSymbols');
    this.$store.dispatch('getGeneralData');
    this.$store.dispatch('initApplication');
  },
  mounted() {
    // get user Device Data
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('mac os')) {
      // this.isMacOS = true;
      document.body.classList.add('macos');
    }

    if (userAgent.includes('iphone')) {
      // this.isIphone = true;
      document.body.classList.add('iphone');
    }

    // dynamically get if the device got rotated/'resized'
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
    window.addEventListener('orientationchange', this.checkOrientation);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkOrientation);
    window.removeEventListener('orientationchange', this.checkOrientation);
  },
  methods: {
    checkOrientation() {
      const isPortrait = window.innerHeight > window.innerWidth;
      this.$store.dispatch('updateOrientation', isPortrait);
    }
  }
}
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-color;
}

nav {
  display: flex;
  align-items: center;
  padding-top: 12px;
  @include respond-to(margin-inline, (
    xxs: $rand-padding / 2,
    l: $rand-padding,
    xxl: $rand-padding * 1.5
  ));
 
  @include respond-to(justify-content, (
    l: end,
  ));

  a {
    margin-left: 8px;
    color: $text-color;
    transition: $transition;
    font-size: 10px;
    @include respond-to(font-size, (
      xxs: 10px,
      l: 12px
    ));

    @include respond-to(margin-left, (
      xxs: 4px,
      l: 8px,
      xxl: 12px
    ));

    &.router-link-exact-active {
      color: $main-color;
      text-shadow: 1px 0 0 currentColor;
      text-decoration: none;
    }

    &.disabled {
      opacity: 0.8;
      pointer-events: none;
    }

    &:hover {
      color: $main-color;
    }
  }

  .link-icon-container {
    position: relative;
    stroke: $main-color;
    stroke-width: 4;
    transition: $transition;
    width: 14px;
    height: 14px;
    @include respond-to(margin-left, (
      xxs: 4px,
      l: 8px,
      xxl: 10px
    ));

    .rect1,
    .rect2 {
      position: absolute;
      width: 7px;
      height: 1px;
      background-color: $text-color;
    }

    .rect1 {
      top: 6px;
      rotate: 20deg;
    }

    .rect2 {
      bottom: 4px;
      rotate: -20deg;
    }
  }
}
</style>
