<template>
  <div class="toasts-container">
    <div v-for="(toast, index) in basics.toastMessages" :key="toast" :class="[{ 'hidden': toast.visible !== true }, toast.type, 'toast']" @click="hideToast($event, index)">
      <span class="toast-header">
        <i v-if="toast.type === 'success' || toast.type === 'Efrolg'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
          </svg>
        </i>
        <i v-if="toast.type === 'error' || toast.type === 'Fehler'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
          </svg>
        </i>
        <span class="text">{{ toast.title }}</span>
      </span>
      <span class="toast-body">{{ toast.description }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ToastComponent',
  computed: {
    ...mapGetters([
      'basics',
    ]),
  },
  methods: {
    hideToast($event, index) {
      console.log('$event: ', index);
      $event.target.closest('.toast').classList.add('self-destruct');
      setTimeout(() => {
        this.$store.dispatch('hideToastMessage', { index });
        console.log('hiding toast message now: ', index);
      }, 300);
    }
  },
  // mounted() {
  //   this.showToast();
  // }
};
</script>

<style lang="scss">
.toasts-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  
  .toast {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-bottom: ($gap * 0.5); 
    background-color: $map-dotted-line;
    padding: ($gap * 1);
    transform: translate(0%, 0);
    color: $text-color-white;
    animation: toastAnimation 1s 3s forwards;
    border-radius: 6px;
    box-shadow: $box-shadow;
    text-align: left;
    @include respond-to(max-width, (
      xxs: 300px,
      l: 450px
    ));

    &.hidden {
      display: none;
    }

    &.success,
    &.Erfolg {
      background-color: $approved;
    }

    &.error,
    &.Fehler {
      background-color: rgba(#e21818, 1);
    }

    &.self-destruct {
      opacity: 0;
      transform: translate(-100%, 0);
    }
    
    .toast-header {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: ($gap * 0.5);
    }
  
    .toast-body {
      font-size: 12px;
    }
  }
}
</style>
