<template>
  <h2>{{ vocabulary.page404.title }}</h2>
  <p class="text404">{{ vocabulary.page404.subTitle }}</p>
  <router-link class="btn icon-left" :to="{ name: 'meinereisen' }">
    <i class="btn-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/>
        </svg>
      </i>
      <span class="text">{{ vocabulary.buttons.backHome }}</span>
  </router-link>
  <!-- <router-link :to="{ name: 'meinereisen' }">To Tours List Ovewview</router-link> -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Page404View',
  computed: {
    ...mapGetters([
      'vocabulary'
    ])
  },
};
</script>

<style scoped lang="scss">
a {
  display: inline-flex;
  color: $text-color-white;
  margin: $gap auto;
}

/* a:hover {
  color: crimson;
    text-decoration: none;
}

a.router-link-exact-active {
  color: #fff;
  background: crimson;
} */

</style>
