<template>
  <h3 class="signature-title">
    {{ vocabulary.pokaYoke.signatureTitle }}
    <i class="signature-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V428.7c-2.7 1.1-5.4 2-8.2 2.7l-60.1 15c-3 .7-6 1.2-9 1.4c-.9 .1-1.8 .2-2.7 .2H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 381l-9.8 32.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.8 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8h8.9c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7L384 203.6V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM549.8 139.7c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM311.9 321c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L512.1 262.7l-71-71L311.9 321z"/>
      </svg>
    </i>
  </h3>
  <button class="btn open-signature" @click="openSignaturePad()">
    {{ vocabulary.pokaYoke.signatueOpenBtnText }}
    <i class="signature-button-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path d="M192 128c0-17.7 14.3-32 32-32s32 14.3 32 32v7.8c0 27.7-2.4 55.3-7.1 82.5l-84.4 25.3c-40.6 12.2-68.4 49.6-68.4 92v71.9c0 40 32.5 72.5 72.5 72.5c26 0 50-13.9 62.9-36.5l13.9-24.3c26.8-47 46.5-97.7 58.4-150.5l94.4-28.3-12.5 37.5c-3.3 9.8-1.6 20.5 4.4 28.8s15.7 13.3 26 13.3H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H460.4l18-53.9c3.8-11.3 .9-23.8-7.4-32.4s-20.7-11.8-32.2-8.4L316.4 198.1c2.4-20.7 3.6-41.4 3.6-62.3V128c0-53-43-96-96-96s-96 43-96 96v32c0 17.7 14.3 32 32 32s32-14.3 32-32V128zm-9.2 177l49-14.7c-10.4 33.8-24.5 66.4-42.1 97.2l-13.9 24.3c-1.5 2.6-4.3 4.3-7.4 4.3c-4.7 0-8.5-3.8-8.5-8.5V335.6c0-14.1 9.3-26.6 22.8-30.7zM24 368c-13.3 0-24 10.7-24 24s10.7 24 24 24H64.3c-.2-2.8-.3-5.6-.3-8.5V368H24zm592 48c13.3 0 24-10.7 24-24s-10.7-24-24-24H305.9c-6.7 16.3-14.2 32.3-22.3 48H616z"/>
      </svg>
    </i>
  </button>
  <div :class="[{ 'collapsed': signaturePadCollapsed }, { 'landscape': !basics.isPortrait}, 'signature-container']">
    <div class="canvas-container">
      <canvas 
        ref="signaturePad" 
        @mousedown="startDrawing" 
        @mouseup="stopDrawing" 
        @mouseleave="stopDrawing" 
        @mousemove="draw" 
        @touchstart.prevent="startDrawing" 
        @touchend.prevent="stopDrawing" 
        @touchcancel.prevent="stopDrawing" 
        @touchmove.prevent="draw"
      >
      </canvas>
      <div class="btn-container">
        <button class="btn close" @click="closeSignaturePad()">{{ vocabulary.buttons.abort }}</button>
        <button class="btn" @click="clearCanvas">{{ vocabulary.buttons.delete }}</button>
        <button class="btn save" @click="saveSignature">{{ vocabulary.buttons.save }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "SignaturePad",
  components: {},
  data() {
    return {
      signaturePadCollapsed: true,
      drawing: false,
      context: null,
      previousPoint: null,
      previousTime: null,
      previousLineWidth: 2,     // Store the previous line width for smoothing
      canvasData: null,          // Store the canvas data to redraw if necessary
      hasDrawn: false // Flag to track if drawing has occurred
    };
  },
  computed: {
    ...mapGetters([
      'vocabulary',
      'activeTour',
      'basics'
    ]),
  },
  mounted() {
    this.initializeCanvas();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);

    // Add event listeners to prevent scrolling
    const canvas = this.$refs.signaturePad;
    canvas.addEventListener('touchstart', this.preventTouchScroll, { passive: false });
    canvas.addEventListener('touchmove', this.preventTouchScroll, { passive: false });
    canvas.addEventListener('touchend', this.preventTouchScroll, { passive: false });
    canvas.addEventListener('touchcancel', this.preventTouchScroll, { passive: false });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);

    const canvas = this.$refs.signaturePad;
    // Remove event listeners to prevent memory leaks
    canvas.removeEventListener('touchstart', this.preventTouchScroll);
    canvas.removeEventListener('touchmove', this.preventTouchScroll);
    canvas.removeEventListener('touchend', this.preventTouchScroll);
    canvas.removeEventListener('touchcancel', this.preventTouchScroll);
  },
  methods: {
    initializeCanvas() {
      const canvas = this.$refs.signaturePad;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      this.context = canvas.getContext("2d");
      this.context.lineWidth = this.previousLineWidth;
      this.context.lineCap = "round"; // Set the line cap to round
      this.context.lineJoin = "round"; // Set the line join to round
      this.context.strokeStyle = "#000000";
      // if (this.canvasData) {
      //   this.context.putImageData(this.canvasData, 0, 0); // Redraw previous canvas content
      // }
    },
    handleResize() {
      console.log("resized");
      // this.clearCanvas();
      this.initializeCanvas();
    },
    preventTouchScroll(event) {
      event.preventDefault();
    },
    startDrawing(event) {
      this.drawing = true;
      this.previousPoint = this.getCanvasCoordinates(event);
      this.previousTime = Date.now();
      this.context.beginPath();
      this.context.moveTo(this.previousPoint.x, this.previousPoint.y);
    },
    stopDrawing() {
      this.drawing = false;
      this.previousPoint = null;
      this.previousTime = null;
      this.context.closePath();
    },
    draw(event) {
      if (!this.drawing) return;
      const currentPoint = this.getCanvasCoordinates(event);
      const currentTime = Date.now();
      const timeDiff = currentTime - this.previousTime;
      const distance = this.getDistance(this.previousPoint, currentPoint);
      const speed = distance / timeDiff;

      // this.context.lineWidth = Math.max(1, Math.min(5, 10 / (speed * 2)));
      // Calculate new line width and apply smoothing
      const minLineWidth = 2;
      const maxLineWidth = 5;
      const newLineWidth = Math.max(minLineWidth, Math.min(maxLineWidth, maxLineWidth / (speed + 1)));
      this.context.lineWidth = this.previousLineWidth + (newLineWidth - this.previousLineWidth) * 0.1; // Smoothing factor

      // Using quadratic curve for smoother lines
      const midPoint = this.getMidPoint(this.previousPoint, currentPoint);
      this.context.quadraticCurveTo(this.previousPoint.x, this.previousPoint.y, midPoint.x, midPoint.y);
      this.context.stroke();

      this.previousPoint = currentPoint;
      this.previousTime = currentTime;
      this.previousLineWidth = this.context.lineWidth; // Update previous line width
      this.hasDrawn = true; // Set the flag to true to indicate drawing has occurred
    },
    getCanvasCoordinates(event) {
      const canvas = this.$refs.signaturePad;
      const rect = canvas.getBoundingClientRect();
      const x = (event.clientX || event.touches[0].clientX) - rect.left;
      const y = (event.clientY || event.touches[0].clientY) - rect.top;
      return { x, y };
    },
    getDistance(point1, point2) {
      const dx = point2.x - point1.x;
      const dy = point2.y - point1.y;
      return Math.sqrt(dx * dx + dy * dy);
    },
    getMidPoint(point1, point2) {
      return {
        x: (point1.x + point2.x) / 2,
        y: (point1.y + point2.y) / 2,
      };
    },
    clearCanvas() {
      const canvas = this.$refs.signaturePad;
      this.context.clearRect(0, 0, canvas.width, canvas.height);
      this.hasDrawn = false; // Reset the drawing flag
    },
    saveSignature() {
      if (this.hasDrawn) { // Only save if drawing has occurred
        const canvas = this.$refs.signaturePad;
        const dataURL = canvas.toDataURL("image/png");
        // Do something with the dataURL, e.g., send it to a server or save it locally.
        // console.log(dataURL);
        this.$store.dispatch('saveSignature', { dataURL });
        this.signaturePadCollapsed = true;
        document.querySelector('html').classList.remove('prevent-scrolling');
        this.hasDrawn = false; // Reset the drawing flag
      }
    },
    openSignaturePad() {
      console.log('open');
      // window.scroll({
      //   top: 0, 
      //   left: 0, 
      //   behavior: 'smooth'
      // });
      this.signaturePadCollapsed = false;
      document.querySelector('html').classList.add('prevent-scrolling');
    },
    closeSignaturePad() {
      this.signaturePadCollapsed = true;
      console.log('close');
      document.querySelector('html').classList.remove('prevent-scrolling');
      this.clearCanvas();
    },
  }
}
</script>

<style scoped lang="scss">
.signature-title {
  display: flex;
  align-items: center;
  gap: ($gap * 0.5);

  .signature-icon {
    display: flex;
    height: 20px;
    width: 20px;
    order: -1;

    svg {
      fill: rgba($text-color, 1);
    }
  }
}

.open-signature {
  background-color: transparent;
  color: rgba($text-color, 1);
  border: 1px solid rgba($text-color, 1);
  @include respond-to(margin-left, (
    xxs: 16px,
    xl: 36px
  ));

  @media (min-width: #{returnBP("xl")}#{$sizing} ) {
    display: none;
  }

  &:hover {
    color: rgba($text-color-white, 1);
    border: 1px solid rgba($text-color, 0);

    .signature-button-icon {
      svg {
        fill: rgba($text-color-white, 1);
      }
    }
  }

  .signature-button-icon {
    display: flex;
    height: 20px;
    width: 20px;
    
    svg {
      fill: rgba($text-color, 1);
      transition: $transition;
    }
  }
}

.signature-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($text-color, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  opacity: 1;
  z-index: 5;
  overflow: hidden; /* Prevent overflow issues */
  @include respond-to(margin-left, (
    xxs: 0,
    xl: 36px
  ));
  @include respond-to(padding, (
    xxs: ($gap * 1),
    xl: ($gap * 2) ($gap * 2)
  ));
  @include respond-to(height, (
    xxs: 100%,
    xl: 200px
  ));

  &.collapsed {
    visibility: hidden;
    opacity: 0;
  }
  
  @media (min-width: #{returnBP("xl")}#{$sizing} ) {
    position: relative;
    max-width: 600px;
    background-color: transparent;

    .close,
    .save {
      display: none;
    }

    &.collapsed {
      visibility: visible;
      opacity: 1;
    }
  }


  &.landscape {
    .canvas-container {
      top: 0;
      bottom: 0;

      canvas {
        @include respond-to(height, (
          xxs: 80vh,
          xl: 200px
        ));
      }
    }
  }

  .canvas-container {
    position: relative;
    top: 30vh;
    
    @media (min-width: #{returnBP("xl")}#{$sizing} ) {
      position: absolute;
      top: 0;
      left: 0;
    }

    canvas {
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 200px;
      border: 1px solid rgba($text-color, 1);
      background-color: rgba($text-color-white, 1);
      touch-action: none; /* Disable default touch actions */

      @media (min-width: #{returnBP("xl")}#{$sizing} ) {
        position: relative;
        width: 600px;
        height: 200px;
        background-color: transparent;
      }
    }
    
    .btn-container {
      position: absolute;
      left: 15px;
      top: 0;
      display: flex;

      .btn {
        margin-left: 0;
        background-color: transparent;
        color: $text-color;
        padding: 0;
        text-decoration: underline;
      }
    
      button {
        margin: 10px;
      }
    }
  }
}
</style>
